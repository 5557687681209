import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import {
  BreadCrumb,
  Background,
  MarkdownContent,
  Section,
  Container,
  SectionPageTitle,
  Flex,
  FlexTwoCol,
  FlexVwCol,
} from "../components/SectionTags"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import BreakpointUp from "../components/Media/BreakpointUp"
import CaresLogo from "../images/aes-cares-logo.png"
import CareTeam1 from "../images/caretem-1.jpg"
import CareTeam2 from "../images/caretem-2.jpg"
import CareTeam3 from "../images/caretem-3.jpg"
import CareTeam4 from "../images/caretem-4.jpg"
import CareTeam5 from "../images/caretem-5.jpg"

const Sections = styled(Section)`
  &:before{
    height:50%;
    ${BreakpointUp.md`
      height:70%;
    `}
  }
`
const FlexLg = styled(Flex)`
  ${BreakpointUp.lg`
    padding:0 20px;
  `}
`
const FlexLgTwoCol = styled(FlexTwoCol)`
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const FlexLgVwCol = styled(FlexVwCol)`
  ${BreakpointUp.md`
    flex: 0 0 33.33333%;
    max-width:33.33333%;
  `}
`
const ProductMedia = styled.div`
  text-align:left;
  margin-bottom: 30px;
  ${BreakpointUp.lg`
    text-align:right;
    margin-bottom: 0;
  `}
`
const OffersSection = styled.div`
  position:relative ;
  margin-top:30px;
  ${BreakpointUp.lg`
    padding-right:calc((100% - 990px) / 2);
  `}
  ${BreakpointUp.xxl`
    padding-right:calc((100% - 1350px) / 2);
  `}  
`
const OffersContentWrap = styled.div`
  position: relative;
  background: white;
  box-shadow: 0 15px 65px rgb(0 0 0 / 15%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 30px 20px;
  ${BreakpointUp.lg`
    padding: 60px;
  `}
  ${BreakpointUp.xl`
    padding: 80px;
  `}
  ${BreakpointUp.xxl`
    padding-left:calc(100% - 1350px);
  `}  
`
const ListCares = styled.div`
  margin-bottom:30px;
`
const ListCaresItem = styled.div`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 900;
  color: #000;
  font-size: 20px;
  line-height: 34px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
    line-height: 50px;
  }
  > span{
    color:#F40808;
  }
`
const OddEvenWrap = styled.div`
  position: relative;

`
const OddEvenThumb = styled.div`
  position: relative;
  border-radius: 8px;
`
const OddEvenContent = styled.div`
  background: #edf8ff;
  ${BreakpointUp.lg`
    width: calc(55vw - 100px);
  `}
`
const OddEvenDesc = styled.div`
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  max-width: 440px;
  & p{
    font-style: italic;
    font-weight: 600;
    ${BreakpointUp.md`
      font-size: 18px;
      line-height: 30px;
    `}
    ${BreakpointUp.xxl`
      font-size: 20px;
      line-height: 34px;
    `}
    ${BreakpointUp.xxl`
      font-size: 22px;
      line-height: 36px;
    `}
  }
  & i{
    color:#0059B2;
    font-weight:700;
  }
`
const Title = styled.strong`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin: 0 0 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  color: #0059b2;
  opacity: 0.1;
  display: none;
  ${BreakpointUp.xxl`
    display: block;
    font-size: 44px;
    line-height: 58px;
    right: 0;
  `}
 
`
const OddEvenItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  flex-direction: column;
  ${BreakpointUp.sm`
    flex-direction: row;
  `}
  ${BreakpointUp.md`
		padding-bottom:50px;
	`}
  .content{
    padding:0 30px;
  }
  &.odd{
    
    ${BreakpointUp.lg`
      padding-left: calc((100% - 790px)/2);
    `}
    ${BreakpointUp.xl`
      padding-left: calc((100% - 1000px)/2);
    `}
    ${BreakpointUp.xxl`
      padding-left: calc((100% - 1350px)/2);
    `}
    .content{     
      ${BreakpointUp.lg` 
        margin-right:auto;
        .desc{
          margin-right:auto;
        }
      `}
      ${BreakpointUp.xl` 
        padding-left: 100px;
      `}
    }
  }
  &.even{
    ${BreakpointUp.sm`
      flex-direction: row-reverse;
    `}
    ${BreakpointUp.lg`
      padding-right: calc((100% - 1350px)/2);
    `}
    .content{     
      ${BreakpointUp.lg`
        margin-left:auto;
        .desc{
          margin-left:auto;
        }
      `}
      ${BreakpointUp.xl`
        padding-right: 100px;
      `}
    }
  }
  
`

class CaresTeam extends React.Component {
  render() {
    const pageData = this.props.data.contentfulCaresTeamPage
    return (
      <Layout>
        <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Sections xpt="120px" mpt="120px" pt="90px" pb="0"
        bgBeforeWidth="100%"
        topAfter="0"
        bgBefore="linear-gradient(to bottom,#ffffff 0%, #f5f5f5 100%)">
          <Background height="auto" width="50%" left="0px"><IndustryHeroBg /></Background>
          <Container>
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link> AES C.A.R.E.S
            </BreadCrumb>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexLgTwoCol>
                <SectionPageTitle>{pageData.heroSectionTitle}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </FlexLgTwoCol>
              <FlexLgTwoCol>
                <ProductMedia>
                <img src={CaresLogo} alt="AES C.A.R.E.S" />
                </ProductMedia>
              </FlexLgTwoCol>
            </Flex>
          </Container>
          <OffersSection>
            <Background height="auto" width="50%" top="auto" right="0px" bottom="0"><IndustryHeroBg /></Background>
            <Title>C.A.R.E.S</Title>
            <Container maxWidth="100%" pl="0" pr="0">
              <OffersContentWrap>
                <FlexLg alignItem="center" justifyContent="space-between">
                  <FlexLgTwoCol>
                    <MarkdownContent>
                      <p>Each letter in the word C.A.R.E.S. represents one of the five areas of focus that our team has chosen to prioritize in serving our greater community:</p>
                      <p>We have facilitated several different outreach efforts through the efforts of our AES C.A.R.E.S. Team in recent years, including food drives, hurricane emergency relief drives, various fundraisers for other community initiatives, and more. </p>
                    </MarkdownContent>
                  </FlexLgTwoCol>
                  <FlexLgVwCol>
                    <ListCares>
                      <ListCaresItem><span>C</span> - Children</ListCaresItem>
                      <ListCaresItem><span>A</span> - Academics</ListCaresItem>
                      <ListCaresItem><span>R</span> - Restoration</ListCaresItem>
                      <ListCaresItem><span>E</span> - Eating</ListCaresItem>
                      <ListCaresItem><span>S</span> - Shelter</ListCaresItem>
                    </ListCares>
                  </FlexLgVwCol>
                </FlexLg>
              </OffersContentWrap>
            </Container>
          </OffersSection>
        </Sections>
        
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="50px" pb="30px">
          <Container maxWidth="100%">
            <OddEvenWrap>
              <OddEvenItem className="odd">
                <OddEvenThumb className="thumb"><img src={CareTeam1} alt="Care Team" /></OddEvenThumb>
                <OddEvenContent className="content">
                  <OddEvenDesc className="desc">
                    <p>We hosted school supply drives for a number of local schools. The supplies gathered were distributed to students from low-income areas where families often can’t afford to purchase basic school supplies.</p>                    
                  </OddEvenDesc>
                </OddEvenContent>
              </OddEvenItem>
            
              <OddEvenItem className="even">
                <OddEvenThumb className="thumb"><img src={CareTeam2} alt="Care Team" /></OddEvenThumb>
                <OddEvenContent className="content">
                  <OddEvenDesc className="desc">
                    <p>Our team held internal fundraisers, including pizza lunches and mini raffles among the AES staff. These efforts resulted in <i>$1,500.00</i> being raised and contributed to the Shepherd’s House, a homeless shelter based in Mount Airy, NC.</p>                    
                  </OddEvenDesc>
                </OddEvenContent>
              </OddEvenItem>

              <OddEvenItem className="odd">
                <OddEvenThumb className="thumb"><img src={CareTeam3} alt="Care Team" /></OddEvenThumb>
                <OddEvenContent className="content">
                  <OddEvenDesc className="desc">
                    <p>We collected supplies for The Shepherd’s House as well. Employees donated trash bags, needed cleaning supplies, and other essential items.</p>                    
                  </OddEvenDesc>
                </OddEvenContent>
              </OddEvenItem>
              
              <OddEvenItem className="even">
                <OddEvenThumb className="thumb"><img src={CareTeam4} alt="Care Team" /></OddEvenThumb>
                <OddEvenContent className="content">
                  <OddEvenDesc className="desc">
                    <p>The pizza lunch and raffle fundraisers mentioned above also raised <i>$1,500.00</i> to support the efforts of our local Habitat for Humanity chapter.</p>                    
                  </OddEvenDesc>
                </OddEvenContent>
              </OddEvenItem>
              
              <OddEvenItem className="odd">
                <OddEvenThumb className="thumb"><img src={CareTeam5} alt="Care Team" /></OddEvenThumb>
                <OddEvenContent className="content">
                  <OddEvenDesc className="desc">
                    <p>Our employees also participate in fundraisers for the North Carolina Autism Society, and have joined in the annual Walk for Autism held locally.</p>                    
                  </OddEvenDesc>
                </OddEvenContent>
              </OddEvenItem>
            </OddEvenWrap>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default CaresTeam

export const pageQuery = graphql`
  query CaresTeamQuery {
    contentfulCaresTeamPage {
      metaTitle
      metaDescription
      heroSectionTitle
      heroDescription {
        childMarkdownRemark{
          html
        }
      }
    }
  }
`